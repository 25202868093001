<div class="modal-header">
  <h4 class="mb-0">Set tour preferences</h4>
</div>
<div class="modal-body">

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>How much will I have to spend?</label>
        <ng-select  placeholder="How much will I have to spend?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.price" >
            <ng-option [value]="0">
                <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt=""> <span class="ms-1" i18n>Affordable</span>
            </ng-option>
            <ng-option [value]="1">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt=""> <span class="ms-1" i18n>Moderate</span>
            </ng-option>
            <ng-option [value]="2">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt=""> <span class="ms-1" i18n>Expensive</span>
            </ng-option>
            <ng-option [value]="3">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/money.png" width="30" alt=""> <span class="ms-1" i18n>Luxury</span>
            </ng-option>
        </ng-select>
    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>How much will I have to walk?</label>
        <ng-select  placeholder="How much will I have to walk?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.walk" >
            <ng-option [value]="0">
                <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt=""> <span class="ms-1" i18n>Not much</span>
            </ng-option>
            <ng-option [value]="1">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt=""> <span class="ms-1" i18n>A little</span>
            </ng-option>
            <ng-option [value]="2">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt=""> <span class="ms-1" i18n>Plenty of walk</span>
            </ng-option>
            <ng-option [value]="3">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/footsteps.png" width="30" alt=""> <span class="ms-1" i18n>Long walk</span>
            </ng-option>
        </ng-select>
    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>Suitable for what wheather?</label>
        <ng-select  placeholder="Suitable for what wheather?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.weather" >
            <ng-option [value]="'sunny'">
                <img src="../../../../../../assets/img/pictogram/weather-sunny.png" width="30" alt=""> <span class="ms-1" i18n>Sunny</span>
            </ng-option>
            <ng-option [value]="'cloudy'">
              <img src="../../../../../../assets/img/pictogram/weather-cloudy.png" width="30" alt=""> <span class="ms-1" i18n>Cloudy</span>
          </ng-option>
          <ng-option [value]="'rainy'">
            <img src="../../../../../../assets/img/pictogram/weather-rainy.png" width="30" alt=""> <span class="ms-1" i18n>Rainy</span>
        </ng-option>
        <ng-option [value]="'cloudy-sunny'">
          <img src="../../../../../../assets/img/pictogram/weather-cloudy-sunny.png" width="30" alt=""> <span class="ms-1" i18n>Cloudy&sunny</span>
      </ng-option>
           
        </ng-select>
    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>Day or night tour?</label>
        <ng-select  placeholder="Day or night tour?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.daytime" >
            <ng-option [value]="'morning'">
                <img src="../../../../../../assets/img/pictogram/day-morning.png" width="30" alt=""> <span class="ms-1" i18n>Morning</span>
            </ng-option>
            <ng-option [value]="'evening'">
              <img src="../../../../../../assets/img/pictogram/day-evening.png" width="30" alt=""> <span class="ms-1" i18n>Evening</span>
          </ng-option>
          <ng-option [value]="'night'">
            <img src="../../../../../../assets/img/pictogram/day-night.png" width="30" alt=""> <span class="ms-1" i18n>Night</span>
        </ng-option>
      
           
        </ng-select>
    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>Any romantic place?</label>
        <ng-select  placeholder="Any romantic place?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.romantic" >
            <ng-option [value]="0">
                <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt=""> <span class="ms-1" i18n>Not much</span>
            </ng-option>
            <ng-option [value]="1">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt=""> <span class="ms-1" i18n>Some</span>
            </ng-option>
            <ng-option [value]="2">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt=""> <span class="ms-1" i18n>Plenty</span>
            </ng-option>
            <ng-option [value]="3">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/heart.png" width="30" alt=""> <span class="ms-1" i18n>Abundant</span>
            </ng-option>
        </ng-select>
    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>Spots for selfies and photos?</label>
        <ng-select  placeholder="Spots for selfies and photos?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.selfies" >
            <ng-option [value]="0">
                <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt=""> <span class="ms-1" i18n>Not much</span>
            </ng-option>
            <ng-option [value]="1">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt=""> <span class="ms-1" i18n>Some</span>
            </ng-option>
            <ng-option [value]="2">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt=""> <span class="ms-1" i18n>Plenty</span>
            </ng-option>
            <ng-option [value]="3">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt="">
              <img src="../../../../../../assets/img/pictogram/camera.png" width="30" alt=""> <span class="ms-1" i18n>Abundant</span>
            </ng-option>
        </ng-select>
    </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="mb-3">
        <label class="form-label" i18n>Any food or drink stops?</label>
        <ng-select  placeholder="Any food or drink stops?" [clearable]="false" [searchable]="false" i18n-languages
            [(ngModel)]="preferences.food" >
            <ng-option [value]="0">
                <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> <span class="ms-1" i18n>Limited</span>
            </ng-option>
            <ng-option [value]="1">
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> 
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> <span class="ms-1" i18n>Some</span>
            </ng-option>
            <ng-option [value]="2">
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> 
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> 
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> <span class="ms-1" i18n>Plenty</span>
            </ng-option>
            <ng-option [value]="3">
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> 
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> 
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> 
              <img src="../../../../../../assets/img/pictogram/food.png" width="30" alt=""> <span class="ms-1" i18n>Abundant </span>
            </ng-option>
        </ng-select>
    </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-secondary me-1" type="button">Close</button>
  <button class="btn btn-primary" type="button" (click)="updatePreferences()">Save</button>
</div>