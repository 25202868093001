import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '@core/services/config.service';
import { ApiService, AuthService, CityService, ErrorService, NotyService, TourFormService, TourPlatformService, TourService, UtilsService } from '@services';
import { Validator } from 'class-validator';
import { take } from 'rxjs/operators';
import { forEach, merge, map, find, every } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TourPreferencesComponent } from './tour-preferences/tour-preferences.component';
@Component({
  selector: 'app-create-tour',
  templateUrl: './create-tour.component.html',
  styleUrls: ['./create-tour.component.css']
})
export class CreateTourComponent implements OnInit {
  @Input() tour;
  tourForm: FormGroup;
  cities: any[] = [];
  @Input() mode = 'create';
  pageSettings = {
    loaders: {
      uploadFile: false
    }
  };

  errors: any[] = [];
  constructor(public configService: ConfigService, private formBuilder: FormBuilder, private cityService: CityService, private apiService: ApiService, private notyService: NotyService, public modalRef: BsModalRef, private tourFormService: TourFormService, private tourService: TourService, private tourPlatformService: TourPlatformService, private location: Location, private router: Router, private activatedRoute: ActivatedRoute, public authService: AuthService, private errorService: ErrorService, private utilsService: UtilsService, private modalService: BsModalService) {
    this.tourForm = this.formBuilder.group({
      languages: ['', Validators.required],
      city: ['', Validators.required],
      categories: [[], Validators.required],
      tourName: ['', Validators.required],
      tourDescription: ['', Validators.required],
      media: [[], Validators.required],
      sights: [[], Validators.required],
      preferences: [null]
    });
  }

  getErrorByErrorName(key: string):any {
    const errors = this.errors.filter((err) => err.element.includes(key)).map((err) => err.error)
    if(errors.length){
      return errors;
    }
  }

  changedForm(){
    this.tourFormService.tourEdited = this.tourForm.getRawValue();
  }

  dropTourPreferencesModal(){
    console.log(this.tourForm.getRawValue());
    this.modalRef = this.modalService.show(TourPreferencesComponent, {
        initialState: {
          tour: this.tourForm.getRawValue()
        },
        class: 'modal-lg modal-dialog-centered',
      });
      this.modalRef.onHidden.pipe(take(1)).subscribe((reason: string | any) => {
        if (typeof reason !== null) {
          this.notyService.dropNoty('success', $localize `Tour preferences updated`);
          this.tourForm.patchValue({
            preferences: reason
          });
          // You can handle the reason here
        } else {
          console.log('Modal closed');
        }
      });
  }

  removeGalleryItem(index){
    this.tourForm.patchValue({
      media: [...this.tourForm.getRawValue().media.slice(0, index), ...this.tourForm.getRawValue().media.slice(index + 1)]
    });
  }

  /**
   * Gets available cities
   */
  getCities(){
    this.cityService.getAllCities({}).pipe(take(1)).subscribe((x) => {
      this.cities = x;
      if(!this.tour){
        this.tourForm.patchValue({
          city: this.cities[0]._id
        });
      }
    
     

      this.tourForm.patchValue({
          languages: this.configService.availableLanguages[0].code
        });

    })
  }

   /**
* Opens the file selector for image upload
*/
openImageFileSelector() {
  document.getElementById('imageFileSelector').click();
}


/**
* Fires the upload of the image array selected via image input control
*/
uploadImages(event) {
  const files = (event.target as HTMLInputElement).files;
  forEach(files, (x) => {
    this.uploadImage(x);
  })
  event.target.value = '';
}

  /**
* Uploads an image to the server
* @param {obj} x the image object you want to upload
*/
uploadImage(x) {
  var formData: any = new FormData();
  formData.append("filepond", x);
  this.pageSettings.loaders.uploadFile = true;
  this.apiService.uploadFile(formData).subscribe((x) => {
    if (x.url) {
      this.tourForm.patchValue({
        media: [...this.tourForm.getRawValue().media, { url: x.url }]
      });
    }
    this.pageSettings.loaders.uploadFile = false;
  }, () => {
    this.pageSettings.loaders.uploadFile = false;
    this.notyService.dropNoty('error', $localize `The requested file is too large or damaged. Please try again.`);
  })
}

getErrorsForTour(id: string) {
  this.errorService.getErrorsByTourId({id}).subscribe((res) => {
    if (res && res.length) {
      this.errors = [...res]
    }
  })
}



  submitTour(){
    if(this.tourForm.valid){
      if(every(this.tourForm.getRawValue().sights, (s)=>{return s.languages[0] === this.tourForm.getRawValue().languages})){
        this.modalRef.hide();
        this.tourForm.patchValue({
          languages: [this.tourForm.getRawValue().languages]
        });
        const tourObj = merge(this.tourForm.getRawValue(), {
          sights: map(this.tourForm.getRawValue().sights, (s)=>{
            return s._id
          }),
          tourName: [{
            language: this.tourForm.getRawValue().languages[0],
            value: this.tourForm.getRawValue().tourName
          }],
          tourDescription: [{
            language: this.tourForm.getRawValue().languages[0],
            value: this.tourForm.getRawValue().tourDescription
          }],
        });

        if(this.mode === 'create'){
          this.tourService.createTour(tourObj).subscribe(()=>{
            this.notyService.dropNoty('success', $localize `Tour created successfully and is now in validation process. You will be notified when it is validated!`);
            this.tourPlatformService.tourListFilterEvent.next(merge(this.tourPlatformService.tourListFilterEvent.value, {forceRefresh: true}));
          })
        } else{
          tourObj._id = this.tour._id;
          this.tourService.modifyTour(tourObj).subscribe(()=>{
            this.notyService.dropNoty('success', $localize `Tour has been modified and is now in validation process. You will be notified when it is validated!`);
            this.tourPlatformService.tourListFilterEvent.next(merge(this.tourPlatformService.tourListFilterEvent.value, {forceRefresh: true}));
          })
        }
      } else{
        this.notyService.dropNoty('error', $localize`The language of the sight is different then the language of the tour`);
        return;
      }
     
    
    }else{
      this.notyService.dropNoty('error', $localize`Please fill all the required fields`);
    }
  }



  addedSightToTour($event){
    if(this.tourForm.getRawValue().languages === $event.languages[0]){
      if(find(this.tourForm.getRawValue().sights, (s: any)=>{
        return s._id === $event._id;
      })){
        this.tourForm.patchValue({
          sights: map(this.tourForm.getRawValue().sights, (s: any)=>{
            if(s._id === $event._id){
              s.isAdded = true;
              return s;
            } else{
              return s;
            }
          }) 
        });
      } else{
        this.tourForm.patchValue({
          sights: [...this.tourForm.getRawValue().sights, $event]
        });
      }
  
      
      this.changedForm();
      this.notyService.dropNoty('success', $localize `Sight added to tour`)
    } else{
      this.notyService.dropNoty('error', $localize`The language of the sight is different then the language of the tour`);
    }
   
    
  }

  removeSightFromTour($event){
    this.tourForm.patchValue({
      sights: [...this.tourForm.getRawValue().sights.filter((x) => { return x._id !== $event._id })]
    });
    this.changedForm();
    this.notyService.dropNoty('info', $localize `Sight removed from tour`)
  }

  getTourById(){
      this.tourService.getTour({id: this.tour._id}).subscribe((tour) => {
        console.log(tour)
        const substringLength = 50;
        tour.sights = map(tour.sights, (sight) => {
          return merge(sight, {
            name: sight.tourName.value.length > substringLength ? sight.tourName.value.substring(0, substringLength) + '...' : sight.tourName.value,
            description: sight.tourDescription.value.length > substringLength ? sight.tourDescription.value.substring(0, substringLength) + '...' : sight.tourDescription.value
          });
        });
        this.tourForm.patchValue({tourName: tour.tourName.value});
        this.tourForm.patchValue({tourDescription: tour.tourDescription.value});
        this.tourForm.patchValue({media: tour.media});
        this.tourForm.patchValue({sights: tour.sights});
        this.tourForm.patchValue({city: tour.city._id});
        this.tourForm.patchValue({categories: tour.categories});
        this.tourForm.patchValue({preferences: tour.preferences});
        console.log(tour.languages[0])
        this.tourForm.patchValue({languages: tour.languages[0]});
        this.getErrorsForTour(tour._id)
      });
  }

  copyToClipboard(){
    if(navigator && navigator.clipboard){
      const baseUrl = window.location.origin;
      const path = this.location.prepareExternalUrl(this.router.url.split('?')[0]);
      let queryParams = new URLSearchParams(this.activatedRoute.snapshot.queryParams);
      queryParams.set('tId', this.tour._id);
      let queryString = queryParams.toString();
      this.notyService.dropNoty('success', $localize `Link copied to clipboard`);
      return navigator.clipboard.writeText(queryString ? `${baseUrl}${path}?${queryString}` : `${baseUrl}${path}`);
    
    }
  }

  ngOnInit() {
    this.getCities();
    if(this.tour){
      this.getTourById();
    }
  }

}
