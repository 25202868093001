import {
    AuthService as SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser
} from 'angularx-social-login';
import { Subject } from 'rxjs';

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthService } from '@services';

import { UserProfile } from '../../../../shared/models';
import { take } from 'rxjs/operators';
import { LocaleService } from '@core/services/misc/locale.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Injectable()
export class SocialLoginService {
  loginStateChanged: Subject<UserProfile> = new Subject<UserProfile>();

  constructor(
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private localeService: LocaleService,
    private modalService: BsModalRef,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  mapSocialUser(socialUser, provider){
    if(provider === 'google'){
      return {
        email: socialUser.email,
        password: socialUser.id,
        firstName: socialUser.givenName,
        lastName: socialUser.familyName
      };
    } else{
      return {
        email: socialUser.email,
        password: socialUser.id,
        firstName: socialUser.name.indexOf(' ') === -1 ? socialUser.name : socialUser.name.split(' ')[0],
        lastName: socialUser.name.indexOf(' ') === -1 ? socialUser.name : socialUser.name.split(' ')[1]
      };
    }
  }

  async performSocialLogin(email, password){
    await this.authService.login(email, password).pipe(take(1)).subscribe(()=>{
      return this.modalService.hide();
    });
  }

  signIn(provider: string): Promise<void> {
    const activeLanguage = _.find(this.localeService.locales, (language: any)=>{return language.locale === this.locale});
    return this.signInByProvider(provider)
      .then((socialUser) => {
        if (socialUser) {
          console.log(socialUser)
          socialUser = this.mapSocialUser(socialUser, provider);
          this.authService
          .registerOrLogin(socialUser.email, socialUser.password, provider, {
            email: socialUser.email,
            password: socialUser.password,
            firstName: socialUser.firstName,
            lastName: socialUser.lastName,
            locale:  activeLanguage.shortHand ?  activeLanguage.shortHand  : 'en',
            provider
          })
          .pipe(take(1))
          .subscribe(
            (x) => {
              this.performSocialLogin(socialUser.email, socialUser.password);
              return x;
            },
            (err) => {
             return false;
            }
          );
        }else{
          return Promise.resolve();
        } 

        //if (socialUser) {

         /*  const authToken = socialUser.authToken;
          this.authService
            .socialLogin(provider, authToken)
            .then((result) => {
              this.socialAuthService.signOut().then(() => {
                this.loginStateChanged.next(result);
              });
            })
            .catch((error) => {
              this.loginStateChanged.error(error);
            }); */
        //}
      })
      .catch((error) => {
        console.error(error);
        this.loginStateChanged.error(error);
      });
  }

  async signInByProvider(provider: string): Promise<any> {
   /*  if(provider === 'google'){
      const user = await GoogleAuth.signIn();;
      return user;
    } else if(provider === 'facebook'){
      const FACEBOOK_PERMISSIONS = [
        'email',
        'user_gender',
      ];
      const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
      if (result.accessToken) {
         /*  const user = await this.getUserInfo(result);
          return user; */
      /* } else{
        return false;
      } */
     
    
  }
}
