import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tour-preferences',
  templateUrl: './tour-preferences.component.html',
  styleUrls: ['./tour-preferences.component.css']
})
export class TourPreferencesComponent implements OnInit {

  @Input() tour;
  preferences: any = {
    price: 0,
    walk: 0,
    weather: 'sunny',
    daytime: 'morning',
    heart: 0,
    romantic: 0,
    selfies: 0,
    food: 0
  }

  constructor(private modalService: BsModalService, private modalRef: BsModalRef) { }

  updatePreferences(){
    this.modalService.setDismissReason(this.preferences);
    this.modalRef.hide();
  }

  ngOnInit() {
    if(this.tour && this.tour.preferences){
      this.preferences = this.tour.preferences;
    }
  }

}
